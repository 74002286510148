<template>
  <div>
    <div class="has-background-secondary">
      <div class="container content py-6">
        <div class="column is-8-desktop">
          <h1 class="has-text-white pb-4">What is Plumb?</h1>
          <p class="has-text-white is-size-5">
            A new search engine. A way to find more of what’s on the web without being tracked, monitored, or surveilled as you surf. Plumb allows you to discover safely; search without being watched and get results from more of the internet. And, as the internet is growing new technologies, we’re planning on indexing them, too.
          </p>
        </div>
      </div>
    </div>

    <section id="why-plumb" class="has-background-blue-5 has-text-blue-1">
      <div class="container content py-6">
        <div class="column is-8-desktop">
          <h2 class="has-text-white">Plumb is a search engine that shows ads, not an ad platform that provides search.</h2>
          <p>We believe that most people would rather pay directly for search rather than pay indirectly with the rest of our data for the rest of our lives. We are making Plumb a search experience so good that you’ll be willing to subscribe to something that has been “free.” As such, we are a work in progress.</p>
          <p>
            Our customers (you, we hope!) are going to help us finish building it.
          </p>
        </div>
      </div>
    </section>

    <section id="how-plumb-makes-money" class="has-background-blue-4 has-text-white">
      <div class="container content py-6">
        <div class="column is-8-desktop">
          <h2 class="has-text-white">Today and tomorrow. Growing to Search Independence</h2>
          <p>
            At launch, Plumb will be a work in progress, and you will be a part of how we evolve. Plumb uses our own index to answer your questions—an independent index developed outside of Big Search’s sandbox—and even at nearly 2 billion pages, it’s neither big enough, nor recent enough, by far.
          </p>
          <p>
            So, whenever we encounter a URL we don’t recognize, it goes into our queue for indexing.
          </p>
          <p>
            The bottom line is, the more you use Plumb the better it gets and the closer you are, we all are, to achieving Search Independence.
          </p>
          <p>
            Please read our <router-link class="has-text-white has-underline" to="/DoDI">Declaration of Digital Independence (DoDI)</router-link> and you’ll see clearly how we believe we all should be treated by all digital platforms. But...especially search engines.
          </p>
          <p>
            As Plumb grows we will also be adding third party data (such as Analytics and Traffic Volumes) to our ranking algorithms and we will always be as transparent as possible regarding how we rank sites. Some of that code is proprietary and we will always be as transparent as prudent business allows.
          </p>
        </div>
      </div>
    </section>

    <section id="plumb-benefits" class="has-background-blue-3 has-text-white">
      <div class="container content py-6">
        <div class="column is-8-desktop">
          <h2 class="has-text-white">Our own index?</h2>
          <p>
            The truth today is that no one can beat Google for size of index. But we argue that size isn’t everything. Other factors also come into play when it comes to index utility. We may never be as large as the Google index…we may never need to be. However, we are building Plumb to provide a better search experience and to provide more results from further around the internet than any other search engine built on any other index.
          </p>
          <p>
            Our immediate plans are this: At launch Plumb will still sometimes have to rely on results from the Big Search Engines and, where we are required to, we’ll show you their ads, too. As we, together, grow our index, we will all become more Search Independent. Our goal is to have Plumb thrive and, by doing so, to create an independent index that we can all share. To be the card catalog of the internet.
          </p>
          <p>
            We don’t expect to be done this year. We didn’t get to this point of Surveillance Capitalism overnight, and building a better future will take some time, too.
          </p>
          <p>
            We think it’ll be worth all the effort, for all of us. We’re committed to getting there.
          </p>
          <p>
            <a class="has-text-white has-text-bold has-underline" href="#" @click.prevent="$eventBus.$emit('showJoinUs')">Please join us.</a>
          </p>
        </div>
      </div>
    </section>
    <!--
    <section id="plumb-privacy" class="has-background-blue-2 has-text-blue-5">
      <div class="container content py-6">
        <div class="column is-8-desktop"></div>
      </div>
    </section>

    <section id="plumb-mobile" class="has-background-blue-1 has-text-blue-5">
      <div class="container content py-6">
        <div class="column is-8-desktop"></div>
      </div>
    </section>
    -->
  </div>
</template>

<script>
export default {
  name: "About"
}
</script>